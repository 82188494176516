<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="title"
            size="medium"
            placeholder="输入标题"
          ></el-input>
        </nav>
        <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >时间：</label
        >
        <nav class="input">
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="date"
            style="width: 100%"
            size="medium"
          >
          </el-date-picker>
        </nav>

        <nav class="input" style="width: 123px;">
          <el-button @click="reset" size="small">重置</el-button>
          <el-button @click="subimt" type="primary" size="small">{{
            '搜索'
          }}</el-button>
        </nav>
        <nav class="input" style="width:88px">
          <el-button
            @click="downE"
            type="primary"
            size="small"
            v-show="$store.state.btn_if.indexOf('report_log_excel') != -1"
            >{{ btn ? '导出Excel' : '导出中..' }}</el-button
          >
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column type="selection"></el-table-column> -->
        <el-table-column prop="id" width="100" label="编号"> </el-table-column>
        <el-table-column prop="realName" label="用户" width="180">
        </el-table-column>
        <el-table-column prop="userPhone" label="电话"> </el-table-column>
        <el-table-column prop="reportTitle" label="标题"> </el-table-column>
        <el-table-column prop="ip" label="ip"> </el-table-column>
        <el-table-column prop="terminal" label="来源"> </el-table-column>
        <el-table-column prop="viewType" label="查看方式">
          <template slot-scope="ew">
            <p v-if="ew.row.viewType == 1">在线查看</p>
            <p v-if="ew.row.viewType == 2">下载</p>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="时间" sortable="custom">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPage"
        :total="total"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      title="新增友情链接"
      :visible.sync="dialogVisiblexui"
      width="50%"
    >
      <el-form ref="form" :model="form_1" label-width="120px">
        <el-form-item label="友情链接名称">
          <el-input v-model="form_1.linkName"></el-input>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="form_1.linkUrl"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisiblexui = false
            form_1 = {}
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改友情链接" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" label-width="120px">
        <el-form-item label="友情链接名称">
          <el-input v-model="form.linkName"></el-input>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="form.linkUrl"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="dioA" width="30%" @close="">
      <Show @form="checkf"></Show>
      <span slot="footer">
        <el-button @click="dioA = false">取消</el-button>
        <el-button type="primary" @click="download">{{
          btn ? '导出' : '导出中..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import show from '../../components/countDowns/show.vue'

export default {
  data() {
    return {
      dioA: false,
      btn: true,
      currentPage: 1,
      pagesize: 10,
      list: [],
      form: {
        id: '',
        linkName: '',
        linkUrl: ''
      },
      form_1: {
        linkName: '',
        linkUrl: ''
      },
      form_3: {
        phone: this.$cookies.get('phone')
      },
      title: '',
      date: [],
      total: 0,
      dialogVisible: false,
      dialogVisiblexui: false
    }
  },
  methods: {
    reset() {
      this.currentPage = 1
      this.order = ''

      this.date = []
      this.title = ''
      this.getlist()
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    getlist() {
      console.log(this.pagesize)
      console.log(this.currentPage)
      this.$http
        .get('/admin/Report/getLogList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            order: this.order,
            date: this.date.join('~'),
            title: this.title
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res.data.list)
            this.list = res.data.list
            this.total = res.data.totalCount
          } else {
            this.$message.error(res.message)
          }
        })
    },
    checkf(val) {
      this.form_3 = val
      // console.log(this.form_3)
    },
    edit(e) {
      this.form.id = e
      this.dialogVisible = true
      this.$http
        .get('/admin/FriendLink/getById?id=' + e)
        .then(({ data: res }) => {
          this.form.linkName = res.data.linkName
          this.form.linkUrl = res.data.linkUrl
        })
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/FriendLink/edit', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.getlist()
              this.$message.success(res.message)
              this.dialogVisible = false
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    async beforedown() {
      console.log(1)
      const phone = this.$cookies.get('phone')
      if (!!!phone) {
        this.$message.error('没有绑定手机号')
        return
      }
      if (!this.proof) {
        this.dioA = true
      } else {
        this.download()
      }
    },
    async downE() {
      if (this.btn) {
        this.btn = false
        const { data: res } = await this.$http.get(
          '/admin/Report/reportLogExport',
          {
            params: {
              page: this.currentPage,
              pageSize: this.pagesize,
              order: this.order,
              date: this.date.join('~'),
              title: this.title
              // ...this.form_3
            }
          }
        )
        this.btn = true
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          location.href = res.data.url
          // this.dioA = false
        } else {
          this.$message.error(res.message)
        }
      }
    },
    async download() {
      if (this.btn) {
        this.btn = false
        // const { data: res } = await this.$http.get(
        //   '/admin/Common/checkSmsCode',
        //   {
        //     params: {}
        //   }
        // )
        // // console.log(this.btn)
        // this.$forceUpdate()
        if (false) {
          // this.$message.error(res.message)
          // return
        } else {
          this.proof = true
          const { data: res } = await this.$http.get(
            '/admin/Report/reportLogExport',
            {
              params: {
                page: this.currentPage,
                pageSize: this.pagesize,
                order: this.order,
                date: this.date.join('~'),
                title: this.title,
                ...this.form_3
              }
            }
          )
          this.btn = true

          if (res.errorCode == 200) {
            this.$message.success(res.message)
            location.href = res.data.url
            this.dioA = false
          } else {
            this.$message.error(res.message)
          }
        }
      }
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/FriendLink/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'activity_order_num'
        } else {
          s = 'rl_create_time'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    handleSizeChange(e) {
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },
    addedit() {
      if (this.btn) {
        this.$http
          .post('/admin/FriendLink/add', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisiblexui = false
              location.reload()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    subimt() {
      this.getlist()
    }
  },
  created() {
    this.getlist()
  },
  components: {
    Show: show
  }
}
</script>

<style lang="less" scoped>
.upload {
  width: 200px;
  height: 200px;
}
.skyblue {
  color: skyblue;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
